import Service from '../Service';

const resource = "extsaleorder/";

export default {

    pagination(filter, requestID) {
        return Service.post(resource + "pagination", filter, {
            params: { requestID: requestID }
        });
    },

	detailbydocentry(filter, requestID) {
        return Service.post(resource + "detailbydocentry", filter, {
            params: { requestID: requestID }
        });
    },

}