<template>
	<div>
		<s-crud
			title="Orden de venta"
			:config="config"
			:filter="filter"
			search-input
			@rowSelected="rowSelected($event)"
			 
		>
			<template v-slot:options>
				<v-tooltip bottom="">
					<template v-slot:activator="{ on }">
						<v-btn
						v-on="on"
						text
						black 
						small
						@click="viewPreGuide()"
						>
						<i class="fas fa-eye"></i>
						</v-btn>
					</template>
					<span>Visualizar Guía</span>
				</v-tooltip>
			</template>
		</s-crud>

		<v-dialog
			v-if="dialogGuide"
			v-model="dialogGuide"
		>
			<lgs-referral-guide-edit-ov
				:referralGuideSelected="saleOrderSelected"
				@closeDialogReferral="closeDialogReferral()"
				:typeGuide="5"
			></lgs-referral-guide-edit-ov>
		</v-dialog>
	</div>
</template>

<script>
	import _sSaleOrder from "@/services/ExternalTrade/ExtSaleOrderService.js";
	import _sCompany from "@/services/General/GenCompanyService.js"
	import _sParameter from "@/services/General/ParameterService.js";

	import LgsReferralGuideEditOv from '../../Logistics/ReferralGuide/LgsReferralGuideEditOv.vue';

	export default {
		components: { 
			LgsReferralGuideEditOv 
		},

		data() {
			return {
				config: {
					model : {
						DocNum : 'ID'
					},
					service : _sSaleOrder,
					headers : [
						{text : 'N° Documento', value : 'DocNum'},
						{text : 'Cliente', value : 'SorCustomerName'},
						{text : 'N° Embarque', value : 'SorNumberBooking'},
						{text : 'N° Contenedor', value : 'SorNumberContainer'},
					]
				},
				filter : {},
				saleOrderSelected : {
					referralGuideItems: []
				},
				dialogGuide : false,
			}
		},

		methods: {
			closeDialogReferral()
			{
				this.dialogGuide = false;
			},

			viewPreGuide() {
				this.dialogGuide = true;
			},

			rowSelected(rows)
			{
				if(rows.length > 0)
				{
					this.saleOrderSelected = rows[0];
					this.saleOrderSelected.DocEntry = rows[0].DocEntry;
					this.saleOrderSelected.DocNum = rows[0].DocNum;
					this.getDetailSaleOrder();
					this.createStructureGuide();
				}
			},

			getDetailSaleOrder()
			{
				_sSaleOrder
				.detailbydocentry(this.saleOrderSelected, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						var arrayDescription = []
						resp.data.forEach(element => {
							arrayDescription.push(element.RgdDescription);
						});

						let descriptionItem = {
							RgdDescription : arrayDescription.join("\n"),
							SecStatus : 1,
							UsrCreateID : this.$fun.getUserID(),
							UsrUpdateID	: this.$fun.getUserID(),
						}
						console.log('descriptionItem ', descriptionItem);
						this.saleOrderSelected.RegObservation =  arrayDescription.join(" ")
						// this.saleOrderSelected.referralGuideItems = [];
						//////////this.saleOrderSelected.referralGuideItems.push(descriptionItem);
						/* resp.data.forEach(element => {
							element.SecStatus = 1;
							element.UsrCreateID = this.$fun.getUserID();
							element.UsrUpdateID	= this.$fun.getUserID();
						});
						this.saleOrderSelected.referralGuideItems = resp.data; */
					}
				})
			},

			createStructureGuide()
			{
				console.log('sale order ', this.saleOrderSelected);
				this.saleOrderSelected.TypeDocumentDestination = 3;
				this.saleOrderSelected.RegDocumentNumberDestination = this.saleOrderSelected.SorRucClient;
				this.saleOrderSelected.RegBusinessNameDestination = this.saleOrderSelected.SorBusinessName;
				this.saleOrderSelected.RegStartPointAddressDestination = this.saleOrderSelected.SorPointArrivalAdress;
				this.saleOrderSelected.RegAddressArrivalPoint = this.saleOrderSelected.SorPointArrivalAdress;
				// this.saleOrderSelected.RegBrandTransport = this.saleOrderSelected.SorBrandVehicle;
				this.saleOrderSelected.RegBrandTransport = this.saleOrderSelected.SorVehicle; 
				// this.saleOrderSelected.RegPlateTransport = this.saleOrderSelected.SorPlateVehicle;
				this.saleOrderSelected.RegPlateTransport = this.saleOrderSelected.SorBrandVehicle; 
				this.saleOrderSelected.RegDriverLicenceTransport = this.saleOrderSelected.SorDriverLicense;
				this.saleOrderSelected.RegDocumentNumberTransport = this.saleOrderSelected.SorDriverLicense.substring(1);
				this.saleOrderSelected.RegConstancyInscripcionTransport = this.saleOrderSelected.SorConstancyInscription;
				// this.saleOrderSelected.RegNamesDriver = this.saleOrderSelected.SorDriverName;
				this.saleOrderSelected.RegNamesDriver = this.saleOrderSelected.SorDriverShortName;
				this.saleOrderSelected.RegLastNameDriver = this.saleOrderSelected.SorDriverLastName;
				//falta inscriocion y nombre del chofer
				this.saleOrderSelected.RegRucTransport = this.saleOrderSelected.SorRucTransport;
				this.saleOrderSelected.RegBusinessNameTransport = this.saleOrderSelected.SorBusinessNameTransport;
				this.saleOrderSelected.RegNetWeightCargo = this.saleOrderSelected.SorWeightNet;
				this.saleOrderSelected.RegGrossWeightCargo = this.saleOrderSelected.SorWeightGross;
				
				//this.saleOrderSelected.TypeStartPoint = 2;
				/* this.saleOrderSelected.DptIDStartPoint = '20';
				this.saleOrderSelected.PrvIDStartPoint = '01';
				this.saleOrderSelected.DtrIDStartPoint = '14'; */
				var itemDe = {
					RgdLine : 1,
					RgdDescription :  this.saleOrderSelected.SorComments,
					  
					RgdQuantity : this.saleOrderSelected.SorWeightNet,
					RgdTotal : this.saleOrderSelected.SorWeightNet,
					RgdUnitMeasurement : "KGM",
					TypeUnitMeasurement : 3
				}


				itemDe.SecStatus = 1;
				// itemDe.RegID = this.referralGuide.RegID;
				itemDe.UsrCreateID = this.$fun.getUserID();
				itemDe.UsrUpdateID = this.$fun.getUserID();

				this.saleOrderSelected.referralGuideItems = [];
				this.saleOrderSelected.referralGuideItems.push(itemDe)


				this.getCompany();
			},

			getCompany()
			{
				let a = {
					PrmName: "SunshineRuc"
				};
				_sParameter
				.search(a, this.$fun.getUserID())
				.then((r) => {
					var SunshineRuc = r.data.PrmValue;
					if(SunshineRuc != "")
					{
						_sCompany
						.searchbyruc(SunshineRuc, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.saleOrderSelected.RegAddressStartPoint = resp.data.CpyAddress;
								this.saleOrderSelected.RegAddressStartPointBk = resp.data.CpyAddress;
							}
						});
					}
				});
				
			},
		},

	}
</script>
